import React, { useState } from "react";
import { VideoContainer } from "../components/VideoContainer";
import "./IntroductionBlock.scss";
import Boss_pic from "./../pictures/BOSS.jpg";
import Model_pic from "./../pictures/model.jpg";
import discover_pic from "./../pictures/discover.jpg";
import { HiChevronDoubleDown } from "react-icons/hi";
import { Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCookie } from "./../utils";

export const IntroductionBlock = () => {
  const [open, setOpen] = useState(true);
  const { t, i18n } = useTranslation();
  let language = getCookie("i18next");
  let first_video_url = "https://www.youtube.com/embed/eBYM6zQ3--Y";
  let first_video_url_en = "https://www.youtube.com/embed/0lBWCgtsG-k";
  let sec_video_url = "https://www.youtube.com/embed/ZTAK-evk0X0";
  let info_date_time_forSwitch = "2022.10.17";
  let info_date_time_forPaper = "2022.09.05";
  let info_date_time_forSend = "2022.09.01";
  let info_date_time_forBlackbox = "2022.08.24";

  let fb_url_forBlackbox =
    "https://www.facebook.com/photo?fbid=591771049128694&set=a.560899735549159";
  let fb_url_forSend =
    "https://www.facebook.com/photo?fbid=598206305151835&set=a.560899735549159";
  let fb_url_forPaper =
    "https://www.facebook.com/SnakeGameLastCommand/posts/pfbid02HtxqkGLLpBoqVCBX6SibchFTYGjboKwLzEQCysKcJGdY7T3AzDhSsJE3oLrhYxSdl";
  let fb_url_forSwitch =
    "https://www.facebook.com/SnakeGameLastCommand/posts/pfbid0343qpSjkBysqgaUK8RGJftP2VuChb4vf9F1x1tE51mn4iduby4bjddwbdhn5SZo74l";
  return (
    <>
      <div className="twice_block_one">
        <div className="Twice_block_title_Area">
          <div className="platform_wrapper">
            <div className="Twice_block_into">
              <div className="line_container_left">
                <div className="line"></div>
                <div className="cross"></div>
              </div>
              <span>{t("into.title")}</span>
              <div className="line_container_right">
                <div className="cross"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="platform_container">
              <div className="platform_leftArea"></div>
              <div className="platform_mainArea">
                <div class="platform_mainArea_left">
                  <iframe
                    style={{ maxWidth: "640px" }}
                    src="https://store.steampowered.com/widget/1487270/"
                    frameborder="0"
                    width="100%"
                    height="190"
                  ></iframe>
                </div>
                <div className="platform_mainArea_main"></div>
                <div className="platform_mainArea_right">
                  <div
                    className={
                      language === "zh" ? "switch_img" : "switch_img_en"
                    }
                  ></div>
                </div>
              </div>
              <div className="platform_rightArea"></div>
            </div>
          </div>
          <div className="Twice_block_title">
            <span>{t("into.01")}</span>
            <br />
            <span>{t("into.01_2")}</span>
          </div>
          <div className="vidoe_container">
            <VideoContainer
              url={language === "zh" ? first_video_url : first_video_url_en}
            />
            <div className="Video_textArea">
              <div className="Video_textArea_title">{t("into.02")}</div>
              <span>{t("into.03")}</span>
            </div>
          </div>
        </div>
        <div id="info" className="info_wrapper">
          <div className="Twice_block_into">
            <div className="line_container_left">
              <div className="line"></div>
              <div className="cross"></div>
            </div>
            <span>{t("description.part1")}</span>
            <div className="line_container_right">
              <div className="cross"></div>
              <div className="line"></div>
            </div>
          </div>
          <div className="info_picture_Container"></div>
          <div className="Video_textArea">
            <div style={{ color: "#625f69", fontSize: "18px" }}>
              {info_date_time_forSwitch}
            </div>
            <div className="Video_textArea_title">
              <a
                target="_blank"
                href={fb_url_forSwitch}
                className="Read_more"
                rel="noreferrer"
              >
                {t("table.04")}
              </a>
            </div>
            <span>{t("table.des04")}</span>
          </div>
          <div className="Video_textArea">
            <div style={{ color: "#625f69", fontSize: "18px" }}>
              {info_date_time_forPaper}
            </div>
            <div className="Video_textArea_title">
              <a
                target="_blank"
                href={fb_url_forPaper}
                className="Read_more"
                rel="noreferrer"
              >
                {t("table.03")}
              </a>
            </div>
            <span>{t("table.des03")}</span>
          </div>
          <div className="Video_textArea">
            <div style={{ color: "#625f69", fontSize: "18px" }}>
              {info_date_time_forSend}
            </div>
            <div className="Video_textArea_title">
              <a
                target="_blank"
                href={fb_url_forSend}
                className="Read_more"
                rel="noreferrer"
              >
                {t("table.02")}
              </a>
            </div>
            <span>{t("table.des02")}</span>
          </div>
          <div className="Video_textArea">
            <div style={{ color: "#625f69", fontSize: "18px" }}>
              {info_date_time_forBlackbox}
            </div>
            <div className="Video_textArea_title">
              <a
                target="_blank"
                href={fb_url_forBlackbox}
                className="Read_more"
                rel="noreferrer"
              >
                {t("table.01")}
              </a>
            </div>
            <span>{t("table.des01")}</span>
          </div>
        </div>

        <div className="arrow_area">
          <a
            className={!open ? "arrow" : "reverse"}
            onClick={() => setOpen(!open)}
          >
            {!open ? (
              <div className="small_des">{t("other.into")}</div>
            ) : (
              <div></div>
            )}
            <HiChevronDoubleDown
              aria-controls="example-collapse-text"
              aria-expanded={open}
            ></HiChevronDoubleDown>
          </a>
        </div>
        <div id="into" className="twice_block_two">
          <Collapse in={open}>
            <div>
              <div className="Twice_block_into">
                <div className="line_container_left">
                  <div className="line"></div>
                  <div className="cross"></div>
                </div>
                <span>{t("into_des.01")}</span>
                <div className="line_container_right">
                  <div className="cross"></div>
                  <div className="line"></div>
                </div>
              </div>
              <div className="vidoe_container">
                <VideoContainer url={sec_video_url} />
              </div>
              <div className="Video_textArea_two">
                <div className="Video_textArea_title">{t("into_des.02")}</div>
                <span>{t("into_des.03")}</span>
              </div>

              <div className="Twice_block_into_small_Area">
                <div className="Twice_block_into_small">
                  <div className="Twice_block_into_small_left">
                    <img src={Boss_pic} />
                  </div>
                  <div className="Twice_block_into_small_right">
                    <div className="Twice_block_into_small_right_title">
                      {t("boss.01")}
                    </div>
                    <div className="Twice_block_into_small_right_text">
                      {t("boss.01_2")}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="Twice_block_into_small">
                  <div className="Twice_block_into_small_left">
                    <img src={Model_pic} />
                  </div>
                  <div className="Twice_block_into_small_right">
                    <div className="Twice_block_into_small_right_title">
                      {t("boss.02")}
                    </div>
                    <div className="Twice_block_into_small_right_text">
                      {t("boss.02_2")}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="Twice_block_into_small">
                  <div className="Twice_block_into_small_left">
                    <img src={discover_pic} />
                  </div>
                  <div className="Twice_block_into_small_right">
                    <div className="Twice_block_into_small_right_title">
                      {t("boss.03")}
                    </div>
                    <div className="Twice_block_into_small_right_text">
                      {t("boss.03_2")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hide_arrow">
                <a className="arrow reverse" onClick={() => setOpen(!open)}>
                  <HiChevronDoubleDown
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  ></HiChevronDoubleDown>
                  {t("other.hide")}
                </a>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};
