import React from "react";
import "./ImageContainer.scss";
export const VideoContainer = ({ url }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        paddingTop: "56.25%",
        margin: "40px auto 0",
      }}
    >
      <iframe
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
          border: "none",
        }}
      ></iframe>
    </div>
  );
};
