import React from "react";
import "./TitleBlock.scss";
import Fair from "./../pictures/background/fair.png";
import FairMobile from "./../pictures/mobile/mobile_fair.png";
import Logo from "./../pictures/background/main_logo.png";
import LogoMobile from "./../pictures/mobile/mobile_logo.png";
import TimeLogo from "./../pictures/time.png";
import TimeLogo_en from "./../pictures/2022.10.25 PDT.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCookie } from "./../utils";

export const TitleBlock = () => {
  const { t, i18n } = useTranslation();
  let language = getCookie("i18next");
  return (
    <div id="first" className="first_block">
      <div className="top_area">
        <div className="fair_container">
          <Link to="/eye">eye</Link>
          <img src={Fair} />
        </div>
        <div className="fair_container_mobile">
          <img src={FairMobile} />
        </div>
        <div className="logo_warpper">
          <div className="logo">
            <img
              className="time_logo"
              src={language === "zh" ? TimeLogo : TimeLogo_en}
            />
            <img src={Logo} />
          </div>
          <div className="logo_mobile_wapper">
            <img src={LogoMobile} />
            <img
              className="time_logo_mobile"
              src={language === "zh" ? TimeLogo : TimeLogo_en}
            />
          </div>
          <div className="button_area">
            <a
              target="_blank"
              href="https://store.steampowered.com/app/1487270/Last_Command/?"
              rel="noreferrer"
            >
              <div className="button">{t("other.steam")}</div>
            </a>
          </div>
        </div>
      </div>
      <div className="effect"></div>
    </div>
  );
};
