import React, { useState } from "react";
import "./GameBlock.scss";
import { HiChevronDoubleDown } from "react-icons/hi";
import { Collapse } from "react-bootstrap";
import SnakeGame from "../components/SnakeGame";
import { useTranslation } from "react-i18next";

export const GameBlock = () => {
  const url =
    "https://www.facebook.com/SnakeGameLastCommand/posts/412139174108308";
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <div id="game" className="third_block">
        <div className="third_block_main">
          <div className="third_block_main_title_area">
            <div className="third_block_main_title">
              <div className="line_container_left">
                <div className="line"></div>
                <div className="cross"></div>
              </div>
              <span>{t("game.01")}</span>
              <div className="line_container_right">
                <div className="cross"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="game_into_area">
              <div className="game_into_title">{t("game.02")}</div>
              <div className="game_into_text">{t("game.03")}</div>
              <div className="game_into_text_mobile">
                {t("game.mobile")}
                <br />
                {t("game.mobile_02")}
              </div>
            </div>
          </div>
          <div className="game_wrapper">
            <div className="game_container">
              <SnakeGame />
            </div>
            <div className="game_into_area">
              <div className="game_into_toparea_block">
                <div className="game_into_toparea_block_title">
                  {t("game_des.01")}
                </div>
                <div className="game_into_toparea_block_des">
                  {t("game_des.01_1")}
                  <span>W</span>
                  <span>A</span>
                  <span>S</span>
                  <span>D</span>
                  {t("game_des.01_2")}
                  <br />
                  <div className="space_area">
                    <span>Space</span>
                    {t("game_des.01_3")}
                  </div>
                </div>
              </div>
              <div className="arrow_area">
                <a
                  className={!open ? "arrow" : "reverse"}
                  onClick={() => setOpen(!open)}
                >
                  {!open ? (
                    <div className="small_des">{t("other.game")}</div>
                  ) : (
                    <div></div>
                  )}
                  <HiChevronDoubleDown
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  ></HiChevronDoubleDown>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Collapse in={open}>
          <div>
            <div className="game_into_wrapper">
              <div className="game_into_bottomarea">
                <div className="game_into_bottomarea_block">
                  <div className="game_into_bottomarea_block_title">
                    {t("game_des.02")}
                  </div>
                  <div className="game_into_bottomarea_block_des">
                    {t("game_des.02_1")}
                    <br />
                    {t("game_des.02_2")}
                    <br />
                    {t("game_des.02_3")}
                    <a href={url} target="_blank" rel="noreferrer">
                      {t("game_des.02_place")}
                    </a>
                    {t("game_des.02_3_2")}
                    <br />
                    {t("game_des.02_4")}
                    <br />
                    {t("game_des.02_5")}
                  </div>
                </div>
                <div className="game_into_bottomarea_block">
                  <div className="game_into_bottomarea_block_title">
                    {t("game_des.03")}
                  </div>
                  <div className="game_into_bottomarea_block_des">
                    {t("game_des.03_1")}
                  </div>
                </div>
                <div className="game_into_bottomarea_block">
                  <div className="game_into_bottomarea_block_title">
                    {t("game_des.04")}
                  </div>
                  <div className="game_into_bottomarea_block_des">
                    {t("game_des.04_1")}
                  </div>
                </div>
              </div>
              <div>
                <a className="arrow reverse" onClick={() => setOpen(!open)}>
                  <HiChevronDoubleDown
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  ></HiChevronDoubleDown>
                  {t("other.hide")}
                </a>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
