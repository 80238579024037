import React, { useState } from "react";
import "./EAGER.scss";
import image_one from "./../pictures/easter eggs/Twins.png";
export const EXIST = () => {
  return (
    <div className="secret_wrapper_Exitst">
      <div className="login_area">
        <img src={image_one} />
      </div>
    </div>
  );
};
