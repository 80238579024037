import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  width: 100%;
  height: 1200px;
  background-color: white;
  z-index: 999;
  position: absolute;
`;

export const EyePage = () => {
  return (
    <Wrapper>
      <div>
        <pre className="eye">
          When you gaze into the abyss, the abyss also gazes into you...
          <br />
          <samp>
            "........::::.:.::..............................................................................
            ..........::::::::::::::::.::.:........::.....:......::::::.........................................
            ..:::::::::::::::::::::::::::::::::::::::::^^^^^^^^^^^::::::::::....................................
            ..:::::::::::::::::::::::::::^^^^^^^^^~~~~~~~~~~~~~~~~~~~~~^^^^^::::::::............................
            ..:::::::::::::::::::::^^^^^^~~~~!!!!777?????????7?777?77777777!~~~~^^^^::::::......................
            ..::::::::::::::::^^^^^^^~~!!77??JJJJ????77!!!!~~~~~~!!!!!!77??????77!!~~^^^:::::::.................
            :::::::::::::::^^^^~~~~!!7?JJJJ???777!!!!!!~!~~~^:::^^^^.::::^^~!!!7????77!!~^^^^::::::::...........
            :::::::::^^^^^^^^^~~!!7?JJ??777777777777??JJYJJJ?77777!~^:^^:::^^~!~~~~~!77??7!!~~^^^^:::::::.......
            ::::::^^^^^^^^^~~~!7?JJ??77777YYYYYYY555PPPGPPPP55PPG55YYYJJ??55Y?!~^::::^^~77???7!~~~^^^:::::::....
            ::::^^^^^^^^~~~!7?JJ?777???JY555PGPPPGGGGGGBBGGGGGGGGGGGPPPPGPP55YJ?7!!!!~~~7~~!7?J?7!!~^^^:::::::::
            :::^^^^^^^^~~!7?JJJ????JY5PPG5PPGGB########BB#BB##B###BBBGGPPGGPPGGGGP55YJ?7?!!~~!?JJ?7!~~~^^^::::::
            ^^^^^^^~~~~!7?JJ???JYY55PP55YYPGBBGGGGPGGGPPPPPPGPPPGGBBBBG5JJY55PPGGGP55P55?77!~~~~!???!!~~^^^^::::
            ^^^~~^^~~~!?JJ??JJJY55P5YJ??YPGGGGGP555PPPGBBPY?J!JJ?75PGGBBP?!~!!7JY5PPPPPP5J?7!7!~~~!777!!~~~^^^::
            ~~~~^~~~!?J????JJY55YY??77?YPPGGGGGPP5GB#&@@@@@@@!!!^7Y5GGBBGP?~^^^~!7JY555PGGGP5YJ??7777777!~~~^^^:
            ~~~~~~~7?????JJYY5Y?777!77JPP5PPPPPPPB&@@@@@@@@@@@@:^!!YPGGGGG5!:::^^~!7?JY555PGGP5YJ?77!!!!!!!~~^^:
            ~~^~~~7?77??JJYY5Y77!!!!7?YPPGPPPPP5P&@@@@@@@@@@@@@@@^^JPPPP55P?^::::^^~!!7JY5Y?J55YJ??7!!!!!~~^^^^^
            ~~^~~77777?JYYYYJ?7!!!!77?YPGGGGPP55P&@@@@@@@@@@@@@@@@YJYPPPP55PJ^..::::^^~!!7??!~!!?YYJ?77!~~~^~^^^^
            ^^^~!!!7?JJYJJ??777!!!!!77YPPPPPY5JJPB@@@@@@@@@@@@@@BGPYY5PP5YP?:...:.:::^^~!!!~~~~!!!777777!!~~^^::
            ^^^~~!?JYJJJJ??777!!!!!!!7?5PP555YYJYPB&@@@@@@@@@@&BGPY555555P5!::....::^~~~~~~!!!!!~~^^^::^::::::::
            ::^^!?YJ??JJJ?777!!!!!!!!77J55Y55YYYY5YPG#&&&&&&#BGP5JJ55555557::::..::^~~~~~!!!!~~~~^^^^^::::::::..
            ::^~!7?7?JJJ??7777!!~~~~~!!7?YYYJY555YYYJJ5PPP555YYJJJYYJ5PGY!::..:::^^^^~!!777!~~~~^::::::::.......
            ::^~^:^~:^?????777!!~~~~~~~~!7J5555YYJYYYYYY5YJJYJYYY55YY55?~:.::::^^^^!!!!!~!~~~~~~^^^^::..........
            .:^~~^^^^:~??????77!!!!!!!~~~!!?J5YYJJY555YYY55YYYYJYYYYJ?~^::::^^^~~!777!~!!!!~~^^:::::::..........
            .:^^^~~~~~~!!!!~~~~~~~~~~~~~~~~~!7?JJYYY5555555YYYY??77!~^^^^^^^^~!!!~~~~~~~~~~~^^^^:::.............
            ....:::^^^^^^~~~~~~~~~~~^^^^~~~~~^~~~!!77777777!!~~~^^^^~^~~~~!7!!~^~^^~~^^^^:::^^::::..............
            .....::^^^^^^^~~~~~~~~~~~~~~~~~~~~~!!!~~~~^^^~!~^~^~~^~~!!~~~~^^~~~~^::^^^::::::.::.................
            ......:^^^^^^~~~~~~~!!!!!!!!!!!!~!77!!!~~~~!!!~!^^^~~^^~~~~~~~~^^:::::::::::........................
            ....:::^^^^^~~~~~~!!!!!!!!777777!!!!!!!!!!!!7!!~~~~~~~~^~~^^~^^::::::...............................
            .....::^^^~~~~~~!!!!!!77!777!!!77!!!!!!!!!!!!!~~~~~^^^^^^^^^::::...............................
            .....::::^^~~~~~!!!!!!!77777777!!!!!!~~~~~~~^^^^^^^::::::::......................................
            ....:::::^^~~~~~!!!!!777!!!!!!!!!!!!!~~~~~~^^^^^::::::::::........................................"
          </samp>
          <br />
          ...Can you find me?
        </pre>
      </div>
    </Wrapper>
  );
};
