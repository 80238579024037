import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import titile_image from "./../pictures/pictures/title_image.png";
import { Dropdown, Collapse } from "react-bootstrap";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  handleScoll,
  handleScollStop,
  handlePostion,
  handleClick,
  getCookie,
} from "./../utils";
import { useTranslation } from "react-i18next";

export const NavbarContainer = () => {
  const SidebarData = [
    { id: 1, title: "最新消息", path: "info" },
    { id: 2, title: "遊戲特色", path: "into" },
    { id: 3, title: "限時活動抽抽抽", path: "game" },
    { id: 4, title: "限量特典", path: "shop" },
  ];
  const SidebarData_Eng = [
    { id: 1, title: "News", path: "info" },
    { id: 2, title: "Game Features", path: "into" },
    { id: 3, title: "Limited Time Raffle Event", path: "game" },
    { id: 4, title: "Limited Edition", path: "shop" },
  ];
  let language = navigator.language;
  const [sidebar, setSidebar] = useState(false);
  const [currentlanguage, setCurrentLanguage] = useState("");
  const [open, setOpen] = useState(false);
  // dective language;
  const { t, i18n } = useTranslation();
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  useEffect(() => {
    if (!sidebar) {
      handleScoll();
    } else {
      handleScollStop();
    }
  }, [sidebar]);
  useEffect(() => {
    handleLanguage();
  }, [currentlanguage]);
  // 預設
  const handleLanguage = () => {
    if (getCookie("i18next")) {
      let lng = getCookie("i18next");
      setCurrentLanguage(lng);
      return;
    }
    if (language === "zh-TW" || language === "zh" || language === "zh-CN") {
      setCurrentLanguage("zh");
      document.cookie = "i18next=zh";
    } else {
      setCurrentLanguage("en");
      document.cookie = "i18next=en";
    }
  };
  return (
    <>
      <nav className="navbarWapper">
        <div className="containerWapper">
          <div className="titleArea">
            <div
              className="title-image"
              onClick={() => {
                handlePostion("first");
              }}
            >
              <img src={titile_image} />
            </div>
            <div className="mobile">
              <a className="menu-icon" onClick={showSidebar}>
                {!sidebar ? <AiOutlineMenu /> : <AiOutlineClose />}
              </a>
            </div>
          </div>
          <div className="me-auto">
            <div className="icons">|</div>
            <div
              className="nav-link"
              onClick={() => {
                handlePostion("info");
              }}
            >
              {t("description.part1")}
            </div>
            <div className="icons">|</div>
            <div
              className="nav-link"
              onClick={() => {
                handlePostion("into");
              }}
            >
              {t("description.part2")}
            </div>
            <div className="icons">|</div>
            <div
              className="nav-link"
              onClick={() => {
                handlePostion("game");
              }}
            >
              {t("description.part3")}
            </div>
            <div className="icons">|</div>
            <div
              className="nav-link"
              onClick={() => {
                handlePostion("shop");
              }}
            >
              {t("description.part4")}
            </div>
            <div className="icons">|</div>
          </div>
          <div className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items">
              {currentlanguage === "zh" &&
                SidebarData.map((data) => {
                  return (
                    <li
                      className="nav-menu-link"
                      key={data.id}
                      onClick={showSidebar}
                    >
                      <span
                        onClick={() => {
                          handlePostion(data.path);
                        }}
                      >
                        {data.title}
                      </span>
                    </li>
                  );
                })}
              {currentlanguage === "en" &&
                SidebarData_Eng.map((data) => {
                  return (
                    <li
                      className="nav-menu-link"
                      key={data.id}
                      onClick={showSidebar}
                    >
                      <span
                        onClick={() => {
                          handlePostion(data.path);
                        }}
                      >
                        {data.title}
                      </span>
                    </li>
                  );
                })}
              <hr />
              <li
                className="nav-menu-link language_btn"
                onClick={() => setOpen(!open)}
              >
                {t("other.lang")}
              </li>
              {
                // 這邊邏輯有問題
              }
              <Collapse in={open}>
                <div id="example-collapse-text" className="mobile_language">
                  <div
                    className={currentlanguage === "zh" ? "active" : ""}
                    onClick={() => {
                      document.cookie = "i18next=zh";
                      setCurrentLanguage("zh");
                      handleClick("zh");
                    }}
                  >
                    繁體中文
                  </div>
                  <div
                    className={currentlanguage === "en" ? "active" : ""}
                    onClick={() => {
                      document.cookie = "i18next=en";
                      setCurrentLanguage("en");
                      handleClick("en");
                    }}
                  >
                    English
                  </div>
                </div>
              </Collapse>
            </ul>
          </div>
        </div>
        <div className="language">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {currentlanguage === "zh" ? "繁體中文" : "English"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                active={currentlanguage === "zh"}
                onClick={() => {
                  document.cookie = "i18next=zh";
                  setCurrentLanguage("zh");
                  handleClick("zh");
                }}
              >
                繁體中文
              </Dropdown.Item>
              <Dropdown.Item
                active={currentlanguage === "en"}
                onClick={() => {
                  document.cookie = "i18next=en";
                  setCurrentLanguage("en");
                  handleClick("en");
                }}
              >
                English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </>
  );
};
