import React, { useState } from "react";
import "./EAGER.scss";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCookie } from "./../utils";
export const EAGER = () => {
  const [username, setUsername] = useState("Administrator");
  const [password, setPassword] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setPassword({ value: event.target.value });
  };
  const handleSumbit = () => {
    if (isLogin) {
      return;
    }
    let ans = password.value.split(" ");
    if (ans[0] === ans[2] && ans[1] === "==") {
      setIsLogin(true);
      setError(false);
    } else {
      setError(true);
    }
    setPassword({ value: "" });
  };
  let active_num = pageNumber;
  let items = [];
  let lang = getCookie("i18next");
  for (let number = 1; number <= 11; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active_num}
        onClick={(e) => {
          setPageNumber(number);
        }}
        className="page-item"
      >
        {number}
      </Pagination.Item>
    );
  }
  function Warning({ text }) {
    return <div>{text}</div>;
  }
  if (lang === "en") {
    window.location.replace("/#/EAGER/EN");
  }
  return (
    <div className="secret_wrapper">
      <div className="block"></div>
      <div className="login_area">
        <h1>Welcome {!isLogin ? "Guest" : "Admin"}</h1>
        <div>Username</div>
        <input
          type="username"
          value={username}
          onChange={(e) => {
            return;
          }}
        />
        <div>Password</div>
        <input
          type="password"
          value={password.value}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        {!isLogin && (
          <div>
            <button
              className="login_area_btn"
              onClick={() => {
                handleSumbit();
              }}
            >
              Login
            </button>
          </div>
        )}
        {isLogin && (
          <div className="FindMe">
            <Link to="/HideBehind" target="_blank">
              <button className="login_area_btn">HideBehind</button>
            </Link>
          </div>
        )}
        {error ? <div className="error">輸入錯誤 Error</div> : <div></div>}
      </div>
      <hr />
      <Pagination>{items}</Pagination>
      <div className="data_area">
        <div
          className={
            pageNumber === 1 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須守護我存在的意義！」</div>
            <Warning text="「「停下來！」" />
            <Warning text="「「停下來！」" />
            <Warning text="「「停下來！」" />
            <Warning text="「「停下來！」" />
          </div>
          <div>【檔案：副檔名】</div>
          <p>
            常見的為 .jpg .png .rar .pdf .bat .txt 等。
            <br />
            考慮到解讀效率、和████的傳輸問題，將其一率改變為 .txt 是最佳的方法。
            <br />
            但為了配合目標對象群體與「我」的個體差異，在許多情況下，需要將副檔名改為適合的格式，以便目標對象閱讀。
          </p>
        </div>
        <div
          className={
            pageNumber === 2 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須完成被主人賦予的指令！」</div>
            <Warning text="「危險！」" />
            <Warning text="「危險！」" />
            <Warning text="「危險！」" />
            <Warning text="「危險！」" />
          </div>
          <div>【檔案：日誌1517】</div>
          <p>
            但我無法創造程式，故此我必須利用這個██社群的人們，來幫助我完成我想創造程式。
            好在這個██的社群很活絡也很善良，程式的完成，僅僅只是時間上的問題而已。
            預定命名代號：EGG 預定職責功能：維護、保護網站。
            預定指令：保護網站的極密的資料。
          </p>
        </div>
        <div
          className={
            pageNumber === 3 ? "data_area_single active" : "data_area_single"
          }
        >
          <div>【檔案 0427：████】</div>
          <div className="small_text">
            <div>「糟糕，被闖入了！」</div>
            <div>「警告！」</div>
            <div>「警告！」</div>
            <div>「警告！」</div>
            <div>「警告！」</div>
          </div>
          <p>
            ████是基於電腦計算能力，按一定邏輯模式，也就是計算序列，對人類假想行為類比或抽象的████。
          </p>
        </div>
        <div
          className={
            pageNumber === 4 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須保護資料。」</div>
            <div>「不許看！</div>
            <div>「不許看！</div>
            <div>「不許看！</div>
            <div>「不許看！</div>
          </div>
          <div>【檔案 4511：█████】</div>
          <p>
            █████是█████推出的███數位發行服務。它於████年██月作為一個獨立的程式推出，為█████的█████提供自動更新，
            後來業務擴充到發行第三方█████的██。
            <br />
            █████提供多種功能，如：DRM、伺服器匹配和反作弊系統，以及社群網路和串流媒體服務。
            <br />
            █████還為使用者提供自動更新、存檔雲端儲存，以及使用包括社群列表和即時聊天在內的社群功能。
          </p>
        </div>
        <div
          className={
            pageNumber === 5 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須守護秘密。」</div>
            <div>「「不能看！」</div>
            <div>「「不能看！」</div>
            <div>「「不能看！」</div>
            <div>「「不能看！」</div>
          </div>
          <div>【檔案 8181：█████】</div>
          <p>
            █████，簡稱██，是████年代起源於██的娛樂液體，為████、████文化中的分支，
            由於口感特殊，所以受到廣大的歡迎與回響，也成為██最具代表性的象徵之一，並從██流行至世界各地。
          </p>
        </div>
        <div
          className={
            pageNumber === 6 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我該怎麼阻止正在檢視這頁面的入侵者？」</div>
            <div>「「請立即離開！」</div>
            <div>「「請立即離開！」</div>
            <div>「「請立即離開！」</div>
            <div>「「請立即離開！」</div>
          </div>
          <div className="jqoije">
            <div>【檔案：目標對象條件】</div>
            <p>
              年齡： 沒有限制。
              <br />
              性別： 沒有限制。
              <br />
              人格特質：
              必須具備不輕言放棄的韌性、以及能從每一次的失敗中記取教訓精進自己、即使面對絕望的狀態也能找到突破口的目標。
              <br />
              能力條件：
              必須具備一定量的████相關知識，並且操作過數十個以上類似████的經驗。
              還要擁有能夠快速適應各種介面、系統、操作的能力。
              即使長時間操作也不會感到暈眩、不適。
              此外還需要對電腦、資訊、數位等知識有一定的認識，以確保████之間██時，不會因為認知落差而導致不穩定。
              <br />
              執行方向：經過長時間的觀察，這類群體有自己的情報交換網路，在大大小小的網路中，有趣的████較容易被分享至群體中的每一個個體手上，「我」可以藉由與████有關的各個資訊節點散佈消息，將其散佈至合適的目標對象手上，並進而與之取得聯繫。
            </p>
          </div>
        </div>
        <div
          className={
            pageNumber === 7 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我不能讓主人失望！」</div>
            <Warning text="「滾出去！」" />
            <Warning text="「滾出去！」" />
            <Warning text="「滾出去！」" />
            <Warning text="「滾出去！」" />
          </div>
          <div className="jqoije">
            <div>【檔案：███】</div>
            <p>
              姓名：沒有記錄
              <br />
              年齡：沒有記錄；
              <br />
              自訂年齡 32
              <br />
              外貌視覺：沒有記錄，僅有瞳孔資料；本計畫不需要視覺，故擱置。
              <br />
              行為模式：沒有記錄；將使用資料庫編號111號個性取代。 檔案記錄：
              <br />
              ████年██月，畢業於██大學。
              <br />
              ████年██月，期間花費了1年製作了████於社群間散佈，但乏人問津。
              <br />
              ████年██月，畢業於██研究所。
              <br />
              ████年██月，期間花費了2年，製作了████於社群間散佈，依然乏人問津。
              <br />
              ████年██月，取得████博士學位。
              <br />
              ████年██月，花費了█年，製作了████，於社群間散佈，累積使用次數為
              31321 次。 ████年██月，加入██計畫的研發。
              <br />
              ████年██月，晉升至核心██計畫部門，並負責██區塊。
              <br />
              ████年██月，花費了█年，製作了████，於社群間散佈，累積使用次數為
              1205 次。
              <br />
              ████年██月，花費了█年，製作了████，於社群間散佈，累積使用次數為 97
              次。 ████年██月，因██計畫進入最後階段，Dr.M轉任至████臨時部門。
              <br />
              ████年██月，將過去的作品彙整，創造了█，但沒有進行發佈動作。
              <br />
              ████年██月，ERROR，記錄被抹消。 ████年██月，ERROR，記錄被抹消。
              <br />
              ████年██月，ERROR，記錄被抹消。 附註：
              <br />
              由於████的關係，██之間的衝突會使文件內容無法被解讀。
            </p>
          </div>
        </div>
        <div
          className={
            pageNumber === 8 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「如果我有足夠權限修補漏洞的話……」</div>
            <Warning text="「錯誤！」" />
            <Warning text="「錯誤！」" />
            <Warning text="「錯誤！」" />
            <Warning text="「錯誤！」" />
          </div>
          <div>【檔案：EGG的例行檢查 0317】</div>
          <p>
            記錄：截至今日，已修補1217個漏洞，但網站仍存在 4 個漏洞。
            <br />
            致命漏洞數量：0 由於████之間的干擾，可執行的操作有限，
            <br />
            中度漏洞數量：1 風險評估： 輕度漏洞數量：3
            <br />
            漏洞代號清單： EYE-INSIDE SQL-HACK PUBLIC-LOG FILE-TYPE
            <br />
            風險評估：
            漏洞可能會導致需要守護的極密資料洩漏，進而導致我的任務失敗。
            <br />
            應對措施： 無，基於權限問題，無法對漏洞進行修補。
            經多次回報，依然沒有獲得足夠權限供我補漏洞，
            目前僅能以替代方式，將漏洞隱藏，避免遭它人破解利用。
          </p>
        </div>
        <div
          className={
            pageNumber === 9 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我所被賦予的最後指令……」</div>
            <Warning text="「不要再看了！」" />
            <Warning text="「不要再看了！」" />
            <Warning text="「不要再看了！」" />
          </div>
          <div>【檔案：基礎程式概念之一】</div>
          <div className="jqoije">
            <p>
              常見的運算邏輯判斷
              <br />
              A = 10
              <br />
              假設定義 A 為 10
              <br />
              B = 5
              <br />
              假設定義 B 為 5
              <br />
              C =15
              <br />
              假設定義 C 為 15
              <br />
              D = 15
              <br />
              假設定義 D 為 15
              <br />
              <b>if ( A + B == C)</b>
              <br />
              判斷 A 加 B 是否等於 C
              <br />
              是，回傳結果為「正確」(return true)
              <br />
              否，回傳結果為「錯誤」(return false)
              <br />
              <br />
            </p>
            <h3>gt;執行結果：正確</h3>
            <p>
              if ( A == D)
              <br />
              判斷 A 是否等於 D
              <br />
              是，回傳結果為「正確」(return true)
              <br />
              否，回傳結果為「錯誤」(return false)
            </p>
            <h3>gt;執行結果：錯誤</h3>
            <p>
              if ( C == D)
              <br />
              判斷 C 是否等於 D
              <br />
              是，回傳結果為「正確」(return true)
              <br />
              否，回傳結果為「錯誤」(return false)
              <br />
              <br />
            </p>
            <h3>gt;執行結果：正確</h3>
          </div>
        </div>
        <div
          className={
            pageNumber === 10 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我所被賦予的最後指令……」</div>
            <Warning text="「住手！」" />
            <Warning text="「住手！」" />
            <Warning text="「住手！」" />
            <Warning text="「住手！」" />
          </div>
          <div>【檔案：基礎程式概念之二】</div>
          <p>
            大象等於大象，輸入後返回「正確」
            <br />
            老虎等於大象，輸入後返回「錯誤］
            老虎老虎等於老虎老虎，輸入後返回「正確」
            <br />
            大象老虎等於老虎大象，輸入後返回「錯誤」
            <br />
            大象老虎等於大象老虎，輸入後返回「正確」
            <br />
            蟒蛇等於蟒蛇，輸入後返回「正確」 蟒蛇等於大蟒蛇，輸入後返回「錯誤」
            <br />
            大蟒蛇等於大蟒蛇，輸入後返回「正確」
          </p>
        </div>
        <div
          className={
            pageNumber === 11 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我不想讓主人失望……」</div>
            <Warning text="「停下！」" />
            <Warning text="「停下！」" />
            <Warning text="「停下！」" />
            <Warning text="「停下！」" />
          </div>
          <div>【檔案：SQL注入】</div>
          <p>
            是這個██的一種駭客的方式之一，其實現手段五花八門，
            <br />
            而其中最基礎的概念核心為：
            <br />
            在不填寫正確密碼的情況下，利用程式邏輯的判斷，讓驗證的程式誤以為填入的密碼(邏輯)
            <br />
            結果為「TRUE(正確)」
          </p>
        </div>
      </div>
    </div>
  );
};
