import React from "react";
import "./Footer.scss";
import crespirit from "./../pictures/icons/logo_c.png";
import x from "./../pictures/icons/x.png";
import nostuck from "./../pictures/icons/logo_n.png";
import { FaSteam } from "react-icons/fa";
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io";
import Logo from "./../pictures/icons/Crespirit_logo.png";
import { handlePostion } from "./../utils";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer>
      <div className="footerWrapper">
        <div className="left_side">
          <a
            href="https://www.crespirit.com/?"
            target="_blank"
            rel="noreferrer"
          >
            <div className="crespirit_icons">
              <img src={crespirit} />
            </div>
          </a>
          <div className="x_icons">
            <img src={x} />
          </div>
          <a
            href="https://www.facebook.com/HungerDungeon/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="nostuck_icons">
              <img src={nostuck} />
            </div>
          </a>
        </div>
        <div className="right_side">
          <div className="left_area">
            <div className="top_area">
              <div className="pages_section">
                <ul>
                  <li
                    onClick={() => {
                      handlePostion("info");
                    }}
                  >
                    {t("description.part1")}
                  </li>

                  <li
                    onClick={() => {
                      handlePostion("into");
                    }}
                  >
                    {t("description.part2")}
                  </li>
                  <li
                    onClick={() => {
                      handlePostion("game");
                    }}
                  >
                    {t("description.part3")}
                  </li>
                  <li
                    onClick={() => {
                      handlePostion("shop");
                    }}
                  >
                    {t("description.part4")}
                  </li>
                </ul>
              </div>
              <div className="contactus_lineArea"></div>
              <div className="social_icons">
                <a
                  href="https://www.facebook.com/SnakeGameLastCommand/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IoLogoFacebook />
                </a>
                <a
                  href="https://store.steampowered.com/app/1487270/Last_Command/?"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSteam />
                </a>
                <a
                  href="https://twitter.com/crespirit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IoLogoTwitter />
                </a>
                <a
                  href="https://www.crespirit.com/contact-us/?"
                  target="_blink"
                >
                  <img src={Logo} />
                </a>
              </div>
            </div>
            <div className="bot_area">
              <div className="copyright">
                © 2015 - 2022 Crespirit co., Ltd./GemaYue All rights reserved.
              </div>
            </div>
          </div>
          <div className="right_area">
            <div className="grading"></div>
          </div>
        </div>
      </div>
    </footer>
  );
};
