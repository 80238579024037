import React, { useCallback, useEffect, useState } from "react";
import Snake from "./Snake/Snake";
import Food from "./Food/Food";
import "./snake.scss";

const getRandomCoordinates = () => {
  let min = 1;
  let max = 98;
  let x = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
  let y = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
  return [x, y];
};

const SnakeGame = () => {
  const [play, setPlay] = useState(false);
  const [pause, setPause] = useState(true);
  const [gameOverEffect, setGameOverEffect] = useState(false);
  const [food, setFood] = useState(getRandomCoordinates);
  const [speed, setSpeed] = useState(60);
  const [direction, setDirection] = useState("RIGHT");
  const [snakeDots, setSnakeDots] = useState([
    [0, 0],
    [2, 0],
    [4, 0],
    [6, 0],
    [8, 0],
    [10, 0],
    [12, 0],
    [14, 0],
    [16, 0],
    [18, 0],
    [20, 0],
  ]);

  useEffect(() => {
    if (pause || !play) {
      return;
    }
    checkIfCollapsed();
    setTimeout(() => moveSnake(snakeDots, checkIfEat()), speed);
  }, [snakeDots, play, pause]);

  useEffect(() => {
    // document.onkeydown = onKeyDown;
    const onKeyDown = (e) => {
      e = e || window.event;
      if (gameOverEffect) {
        e.preventDefault();
        return;
      }
      switch (e.keyCode) {
        case 87:
          !["DOWN", "UP"].includes(direction) && setDirection("UP");
          break;
        case 83:
          !["DOWN", "UP"].includes(direction) && setDirection("DOWN");
          break;
        case 65:
          !["LEFT", "RIGHT"].includes(direction) && setDirection("LEFT");
          break;
        case 68:
          !["LEFT", "RIGHT"].includes(direction) && setDirection("RIGHT");
          break;
        case 32:
          setPause(!pause);
          e.preventDefault();
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [direction, setDirection, pause, setPause]);

  const moveSnake = useCallback(
    (snakeDots, eaten) => {
      let dots = [...snakeDots];
      let head = dots[dots.length - 1];
      switch (direction) {
        case "RIGHT":
          head = [head[0] + 2, head[1]];
          break;
        case "LEFT":
          head = [head[0] - 2, head[1]];
          break;
        case "DOWN":
          head = [head[0], head[1] + 2];
          break;
        case "UP":
          head = [head[0], head[1] - 2];
          break;

        default:
          break;
      }
      if (direction) {
        dots.push(head);

        eaten ? setFood(getRandomCoordinates()) : dots.shift();

        setSnakeDots([...dots]);
      }
    },
    [direction]
  );
  const checkIfCollapsed = () => {
    let snake = [...snakeDots];
    let head = snake[snake.length - 1];
    snake.pop();
    snake.forEach((dot) => {
      if (head[0] === dot[0] && head[1] === dot[1]) {
        onGameOver();
      }
    });
  };

  const checkIfEat = () => {
    let head = snakeDots[snakeDots.length - 1];
    return head[0] === food[0] && head[1] === food[1];
  };

  const onGameOver = () => {
    handleGameOverEffect();
    setTimeout(() => {
      alert("Game Over");
    }, 1000);
  };
  const onRest = () => {
    if (!pause) {
      return;
    }
    setSnakeDots([
      [0, 0],
      [2, 0],
      [4, 0],
      [6, 0],
      [8, 0],
      [10, 0],
      [12, 0],
      [14, 0],
      [16, 0],
      [18, 0],
      [20, 0],
    ]);
    setDirection("RIGHT");
  };
  const handleGameOverEffect = () => {
    setGameOverEffect(true);
    setPause(true);
    setPlay(true);
    setTimeout(() => {
      setGameOverEffect(false);
    }, 1000);
  };

  return (
    <>
      <div className="game-area">
        <div className={`${gameOverEffect && "game-over"}`}></div>
        <Snake snakeDots={snakeDots} />
        <Food dot={food} />
      </div>
      <div className="button_area">
        <div className="keyboard_area" style={{ display: "none" }}>
          <div className="row_one key">W</div>
          <div className="row_two">
            <div className="key">A</div>
            <div className="key">S</div>
            <div className="key">D</div>
          </div>
        </div>
        <div className="key space" style={{ display: "none" }}>
          Space
        </div>
        <div>
          {!play && (
            <button
              className="start_button"
              onClick={() => {
                setPause((p) => !p);
                setPlay((p) => !p);
              }}
            >
              Play
            </button>
          )}
          {play && (
            <button
              className={pause ? "start_button" : "start_button dont_touch"}
              onClick={() => {
                if (pause === false) {
                  setPause((p) => !p);
                  return;
                }
                setPlay((p) => !p);
                onRest();
              }}
            >
              ReStart
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SnakeGame;
