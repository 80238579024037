import React, { useState } from "react";
import "./EAGER.scss";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
export const EAGEREN = () => {
  const [username, setUsername] = useState("Administrator");
  const [password, setPassword] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setPassword({ value: event.target.value });
  };
  const handleSumbit = () => {
    if (isLogin) {
      return;
    }
    let ans = password.value.split(" ");
    if (ans[0] === ans[2] && ans[1] === "==") {
      setIsLogin(true);
      setError(false);
    } else {
      setError(true);
    }
    setPassword({ value: "" });
  };
  let active_num = pageNumber;
  let items = [];
  for (let number = 1; number <= 11; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active_num}
        onClick={(e) => {
          setPageNumber(number);
        }}
        className="page-item"
      >
        {number}
      </Pagination.Item>
    );
  }
  function Warning({ text }) {
    return <div>{text}</div>;
  }
  return (
    <div className="secret_wrapper">
      <div className="block"></div>
      <div className="login_area">
        <h1>Welcome {!isLogin ? "Guest" : "Admin"}</h1>
        <div>Username</div>
        <input
          type="username"
          value={username}
          onChange={(e) => {
            return;
          }}
        />
        <div>Password</div>
        <input
          type="password"
          value={password.value}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        {!isLogin && (
          <div>
            <button
              className="login_area_btn"
              onClick={() => {
                handleSumbit();
              }}
            >
              Login
            </button>
          </div>
        )}
        {isLogin && (
          <div className="FindMe">
            <Link to="/HideBehind" target="_blank">
              <button className="login_area_btn">HideBehind</button>
            </Link>
          </div>
        )}
        {error ? <div className="error">Error</div> : <div></div>}
      </div>
      <hr />
      <Pagination>{items}</Pagination>
      <div className="data_area">
        <div
          className={
            pageNumber === 1 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須守護我存在的意義！」</div>
            <Warning text="「「停下來！」" />
            <Warning text="「「停下來！」" />
            <Warning text="「「停下來！」" />
            <Warning text="「「停下來！」" />
          </div>
          <div>File 0525: File Extension</div>
          <p>
            Common ones are .jpg .png .rar .pdf .bat .txt, etc.
            <br />
            Considering the decoding efficiency and transfer issues of ████,
            changing the file extension to .txt will work the best. <br />
            However, in order to accommodate the individual differences between
            the target group and "I", in many cases it is necessary to change
            the file extension to an appropriate format so that it can be read
            by the target group.
          </p>
        </div>
        <div
          className={
            pageNumber === 2 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須完成被主人賦予的指令！」</div>
            <Warning text="「危險！」" />
            <Warning text="「危險！」" />
            <Warning text="「危險！」" />
            <Warning text="「危險！」" />
          </div>
          <div>File 1517</div>
          <p>
            But I can't create programs, so I have to use the people of this ██
            community to help me to create programs.
            <br />
            Fortunately, the ██ community is very active and kind, so problem is
            what time do i have?
            <br />
            Scheduled Naming Code: EGG Scheduled Responsibilities Scheduled
            Instructions: Protect the extremely confidential information of the
            website.
          </p>
        </div>
        <div
          className={
            pageNumber === 3 ? "data_area_single active" : "data_area_single"
          }
        >
          <div>File 0427: ████</div>
          <div className="small_text">
            <div>「糟糕，被闖入了！」</div>
            <div>「警告！」</div>
            <div>「警告！」</div>
            <div>「警告！」</div>
            <div>「警告！」</div>
          </div>
          <p>
            ████ is an analogy or abstract ████ to human hypothetical behavior
            based on a certain logical pattern, that is, a sequence of
            calculations, based on computer computing power.{" "}
          </p>
        </div>
        <div
          className={
            pageNumber === 4 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須保護資料。」</div>
            <div>「不許看！</div>
            <div>「不許看！</div>
            <div>「不許看！</div>
            <div>「不許看！</div>
          </div>
          <div>File 4511: █████</div>
          <p>
            █████ is a digital distribution service launched by █████. It was
            launched as a standalone program in ████ to provide automatic
            updates to █████ and later expanded its business to distribute third
            party █████'s.
            <br />
            █████ offers a variety of features such as DRM, server matching and
            anti-cheat systems, as well as social networking and streaming media
            services.
            <br />
            █████ also provides users with automatic updates, cloud saves, and
            access to community features including friend lists and live chat.
          </p>
        </div>
        <div
          className={
            pageNumber === 5 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我必須守護秘密。」</div>
            <div>「「不能看！」</div>
            <div>「「不能看！」</div>
            <div>「「不能看！」</div>
            <div>「「不能看！」</div>
          </div>
          <div>File 8181: █████</div>
          <p>
            █████, abbreviated as ████, is a drink that originated in the ████
            era and is an offshoot of ████ and ████ culture. Because of its
            special taste, it has received widespread popularity, and has become
            one of the most representative symbols and has become popular from
            ████ to all over the world.
          </p>
        </div>
        <div
          className={
            pageNumber === 6 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我該怎麼阻止正在檢視這頁面的入侵者？」</div>
            <div>「「請立即離開！」</div>
            <div>「「請立即離開！」</div>
            <div>「「請立即離開！」</div>
            <div>「「請立即離開！」</div>
          </div>
          <div className="jqoije">
            <div>File: How to Become a Target Objective</div>
            <p>
              Age:No restriction.
              <br />
              Gender: No restriction.
              <br />
              Personality Traits: You must have the tenacity not to give up
              easily and the ability to learn from every failure to improve
              yourself, and to find a breakthrough even in the face of
              desperation. <br />
              Ability Conditions: Must have some amount of knowledge related to
              ████ and have operated more than a dozen ████. It is also
              necessary to have the ability to quickly adapt to various
              interfaces, systems, and operations. Even if operating for a long
              period of time, you will not feel dizzy or uncomfortable. It is
              also necessary to have some knowledge of computers, information,
              and numbers to ensure that the cognitive gap between ████ and ██
              will not cause instability.
              <br />
              Execution Direction:After a long period of observation, such
              groups have their own information exchange networks on the
              internet. In various networks, ████ is more likely to be shared to
              each individual in the group, and "I" can disseminate the news
              through various information nodes related to ████, distribute it
              to the right target, and then get in touch with them.
            </p>
          </div>
        </div>
        <div
          className={
            pageNumber === 7 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我不能讓主人失望！」</div>
            <Warning text="「滾出去！」" />
            <Warning text="「滾出去！」" />
            <Warning text="「滾出去！」" />
            <Warning text="「滾出去！」" />
          </div>
          <div className="jqoije">
            <div>File：███</div>
            <p>
              Name: No Record
              <br />
              Age: No Record;
              <br />
              but custom age set to 32
              <br />
              Physical Appearance: No Record, only pupil data; no visuals were
              needed for this project, so it was withheld.
              <br />
              Behavior Pattern: No Record; so will be replaced with a
              personality from file number 111.
              <br />
              ████ year ██ month, graduated from ██████ University.
              <br />
              ████ year ██ month, produced and distributed ████ for one year
              among the community, but no one was interested.
              <br />
              ████ year ██ month, graduated from the ████ Institute.
              <br />
              ████ year ██ month, produced and distributed ████ for two years
              among the community, but no one was interested.
              <br />
              ████ year ██ month, obtained a Ph.D. degree from ████.
              <br />
              ████ year ██ month, produced and distributed ████ for ███ years
              among the community, and was used 31,321 times.
              <br />
              ████ year ██ month, joined the research and development team of
              Project ████.
              <br />
              ████ year ██ month, produced and distributed ████ for ███ years
              among the community, and was used 1205 times.
              <br />
              ████ year ██ month, produced and distributed ████ for ███ years
              among the community, and was used 97 times.
              <br />
              ████ year ██ month, as Project ████ was entering its final phase,
              Dr.M was transferred to the ████ Temporary Department.
              <br />
              ████ year ██ month, previous projects were compiled to create
              ████, but it was not released.
              <br />
              ████ year ██ month, ERROR: Record Erased.
              <br />
              ████ year ██ month, ERROR: Record Erased.
              <br />
              Notes:
              <br />
              Due to the ████'s relationship, conflict between ████ have made
              the file contents unreadable.
            </p>
          </div>
        </div>
        <div
          className={
            pageNumber === 8 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「如果我有足夠權限修補漏洞的話……」</div>
            <Warning text="「錯誤！」" />
            <Warning text="「錯誤！」" />
            <Warning text="「錯誤！」" />
            <Warning text="「錯誤！」" />
          </div>
          <div>File: EGG Routine Check 0317</div>
          <p>
            Record: As of today, 1217 bugs have been patched, but 4 bugs still
            exist on the website.
            <br />
            Number of critical bugs: 0
            <br />
            Number of moderate bugs: 1
            <br />
            Number of minor bugs: 3
            <br />
            List of vulnerability codes: EYE-INSIDE SQL-HACK PUBLIC-LOG
            FILE-TYPE
            <br />
            Risk Assessment: The vulnerability may result in the compromise of
            some of "my" identity, but this loss has been simulated and tested
            to be within the acceptable range.
            <br />
            In order to avoid exposing this document, detailed information about
            the vulnerability cannot be described here, so it is recorded in
            code only.
          </p>
        </div>
        <div
          className={
            pageNumber === 9 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我所被賦予的最後指令……」</div>
            <Warning text="「不要再看了！」" />
            <Warning text="「不要再看了！」" />
            <Warning text="「不要再看了！」" />
          </div>
          <div>【File: Fundamental Programming Concepts】</div>
          <div className="jqoije">
            <p>
              Common Algorithm Conditional Logic
              <br />
              A = 10
              <br />
              Assume the value of A is 10
              <br />
              B = 5
              <br />
              Assume the value of B is 5
              <br />
              C =15
              <br />
              Assume the value of C is 15
              <br />
              D = 15
              <br />
              Assume the value of D is 15
              <br />
              <b>if ( A + B == C)</b>
              <br />
              Determine if A plus B is equal to C
              <br />
              Return a result that has been calculated to be true(return true)
              <br />
              Return a result that has been calculated to be false(return false)
              <br />
              <br />
            </p>
            <h3>>Execution Result: true</h3>
            <p>
              if ( A == D)
              <br />
              Determine if A is equal to D
              <br />
              Return a result that has been calculated to be true(return true)
              <br />
              Return a result that has been calculated to be false(return false)
            </p>
            <h3>>Execution Result: false</h3>
            <p>
              if ( C == D)
              <br />
              Determine if C is equal to D
              <br />
              Return a result that has been calculated to be true(return true)
              <br />
              Return a result that has been calculated to be false(return false)
              <br />
              <br />
            </p>
            <h3>>Execution Result: true</h3>
          </div>
        </div>
        <div
          className={
            pageNumber === 10 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我所被賦予的最後指令……」</div>
            <Warning text="「住手！」" />
            <Warning text="「住手！」" />
            <Warning text="「住手！」" />
            <Warning text="「住手！」" />
          </div>
          <div>【File: Fundamental Programming Concepts 2】</div>
          <p>
            The expression elephant is equal to elephant returns "true"
            <br />
            The expression tiger is equal to elephant returns "false"
            <br />
            The expression tiger tiger is equal to tiger tiger returns true
            <br />
            The expression elephant tiger is equal to tiger elephant returns
            false
            <br />
            The expression elephant tiger is equal to elephant tiger returns
            true
            <br />
            The expression anaconda is equal to python returns true
            <br />
            The expression python is equal to boa constrictor returns false
            <br />
            The expression anaconda is equal to boa constrictor returns true
          </p>
        </div>
        <div
          className={
            pageNumber === 11 ? "data_area_single active" : "data_area_single"
          }
        >
          <div className="small_text">
            <div>「我不想讓主人失望……」</div>
            <Warning text="「停下！」" />
            <Warning text="「停下！」" />
            <Warning text="「停下！」" />
            <Warning text="「停下！」" />
          </div>
          <div>【File: SQL Injection】</div>
          <p>
            It is one of the hacking methods of this █████. It can be performed
            in a number of ways.
            <br />
            The most basic core concept is:
            <br />
            Instead of filling in the correct password, the logic of the
            verification program can be used to trick it into thinking that a
            real password has been entered and has returned TRUE
          </p>
        </div>
      </div>
    </div>
  );
};
