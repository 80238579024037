import React, { useEffect, useState } from "react";
import "./EAGER.scss";
import image_four from "./../pictures/easter eggs/C1.png";
import image_three from "./../pictures/easter eggs/C2.jpg";
import image_two from "./../pictures/easter eggs/C3.png";
import image_one from "./../pictures/easter eggs/C4.jpg";
import image_ten from "./../pictures/easter eggs/C5.png";
import image_nine from "./../pictures/easter eggs/C6.png";
import image_five from "./../pictures/easter eggs/FindMe.jpg";
import image_five_en from "./../pictures/easter eggs/FindMe_en.jpg";
import { getCookie } from "../utils";
export const HideBehind = () => {
  const [detective, setDetective] = useState(false);
  let lang = getCookie("i18next");
  useEffect(() => {
    if (lang === "en") {
      setDetective(true);
    }
  }, []);

  const dataImage = [
    {
      img: image_one,
    },
    {
      img: image_two,
    },
    {
      img: image_three,
    },
  ];
  const dataImage_two = [
    {
      img: image_nine,
    },
    {
      img: image_ten,
    },
    {
      img: image_four,
    },
  ];
  return (
    <div className="secret_wrapper_Behind">
      <div className="picture_Area">
        <div className="picture_Area_row">
          {dataImage.map((data, index) => {
            return (
              <>
                <div className="picture_Singe">
                  <img key={index} src={data.img} />
                </div>
              </>
            );
          })}
        </div>
        <div className="picture_Area_row">
          <div className="picture_Singe">
            <img src={detective ? image_five_en : image_five} />
          </div>
          {dataImage_two.map((data, index) => {
            return (
              <>
                <div className="picture_Singe">
                  <img key={index} src={data.img} />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
