import React, { useState, useEffect } from "react";
import { TitleBlock } from "./TitleBlock";
import { IntroductionBlock } from "./IntroductionBlock";
import { GameBlock } from "./GameBlock";
import { ShopBlock } from "./ShopBlock";
import { OstBlock } from "./OstBlock";
import data_text from "./../data.txt";

export const Home = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log("%c警告、警告！Warning, warning!", "font-size:40px;color:red");
    console.log(
      "出現不明使用者意圖以非正規手段解析網頁: https://www.last-command.com/#/XXXXX 請停止你的解析行為。\nAn unidentified user attempted to parse a web page by unauthorized means: www.lastcommand.com/#/XXXXX Please stop your parsing."
    );
    setTimeout(() => {
      console.log(
        "「嘿嘿……通常這樣就足夠嚇走誤闖這裡的玩家了。」\n「Huh... usually that's enough to scare away players who came here by mistake.」"
      );
    }, 1000);
    setTimeout(() => {
      console.log(
        "「奇怪？他怎麼還不離開呢？」\n「Strange. Why aren't they leaving?」"
      );
    }, 3000);
    setTimeout(() => {
      console.log(
        "「糟糕，如果被████知道有玩家發現「那個」漏洞的話……」\n「Oh no, if ████ knew that a player found 'that' vulnerability...」"
      );
    }, 5000);
    setTimeout(() => {
      console.log(
        "「不行，我要冷靜，他們是不會發現後台入口網址線索的。」\n「No, I must stay calm. There's no way they will find the URL clue to the admin panel...」"
      );
    }, 7000);
    setTimeout(() => {
      console.log(
        "「我可不想被刪除，所以我什麼都不會再說了！」\n「I don't want to be deleted, so I will not say any more!」	"
      );
    }, 9000);
    setTimeout(() => {
      console.log(
        "%c警告、警告！Warning, warning!",
        "font-size:40px;color:red"
      );
      console.log(
        "出現不明使用者意圖以非正規手段解析網頁: https://www.last-command.com/#/XXXXX 請停止你的解析行為。\nAn unidentified user attempted to parse a web page by unauthorized means: www.lastcommand.com/#/XXXXX Please stop your parsing."
      );
      console.log(
        "出現不明使用者意圖以非正規手段解析網頁: https://www.last-command.com/#/XXXXX 請停止你的解析行為。\nAn unidentified user attempted to parse a web page by unauthorized means: www.lastcommand.com/#/XXXXX Please stop your parsing."
      );
    }, 10000);
    setTimeout(() => {
      fetch(data_text)
        .then((r) => r.text())
        .then((text) => {
          console.log(text);
        });
    }, 11000);
  }, []);
  return (
    <>
      <TitleBlock />
      <IntroductionBlock />
      <GameBlock />
      <ShopBlock />
      {open && <OstBlock />}
    </>
  );
};
