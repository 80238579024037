import React, { useState } from "react";
import "./OstBlock.scss";
import OSTPicture from "./../pictures/pictures/OST.png";
import { useTranslation } from "react-i18next";

export const OstBlock = () => {
  const { t, i18n } = useTranslation();
  return (
    <div id="ost" className="five_block">
      <div className="five_block_leftArea"></div>
      <div className="five_block_mainArea">
        <div className="five_block_videoArea_wrapper">
          <div className="five_block_videoArea">
            <img src={OSTPicture} />
          </div>
          <div>
            <div className="five_block_videoArea_title">{t("ost.01")}</div>
            <div className="five_block_videoArea_into">{t("ost.02")}</div>
            <div className="five_block_videoArea_button">
              <a>
                <div className="button">{t("other.buy")}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="five_block_rightArea"></div>
    </div>
  );
};
