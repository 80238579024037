import React, { useState, useEffect } from "react";
import Shopitems from "./../pictures/pictures/shop_items.png";
import { Collapse } from "react-bootstrap";
import { HiChevronDoubleDown } from "react-icons/hi";
import "./ShopBlock.scss";
import { getCookie } from "./../utils";
import { useTranslation } from "react-i18next";

export const ShopBlock = () => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const url = "https://www.crespirit.com/prods-info/?id=130";
  return (
    <div id="shop" className="four_block">
      <div className="four_block_leftArea">
        <div className="left_top"></div>
        <div className={open ? "left_bottom" : "left_bottom none"}></div>
      </div>
      <div className="four_block_mainArea">
        <div className="four_block_topArea">
          <div className="four_block_topArea_picContianer">
            <img src={Shopitems} />
          </div>
          <div className="four_block_topArea_textArea">
            <div className="four_block_topArea_title">
              <div className="line_container_left">
                <div className="line"></div>
                <div className="cross"></div>
              </div>
              <span>{t("shop.01")}</span>
              <div className="line_container_right">
                <div className="cross"></div>
                <div className="line"></div>
              </div>
            </div>

            <div className="four_block_topArea_text">
              <span>{t("shop.02")}</span>
            </div>
            <div className="four_block_topArea_button">
              <a href={url} target="_blank" rel="noreferrer">
                <div className="button">{t("other.buy")}</div>
              </a>
            </div>
          </div>
        </div>
        <div className="arrow_area">
          <a
            className={!open ? "arrow" : "reverse"}
            onClick={() => setOpen(!open)}
          >
            {!open ? (
              <div className="small_des">{t("other.shop")}</div>
            ) : (
              <div></div>
            )}
            <HiChevronDoubleDown
              aria-controls="example-collapse-text"
              aria-expanded={open}
            ></HiChevronDoubleDown>
          </a>
        </div>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div className="four_block_midArea">
              <div className="four_block_midArea_rowArea">
                <div className="four_block_midArea_single">
                  <div className="four_block_midArea_single_picContainer">
                    <div className="four_block_midArea_single_picContainer_CD"></div>
                  </div>
                  <div className="four_block_midArea_single_textConainter">
                    <div className="four_block_midArea_single_textConainter_title">
                      {t("notice.01")}
                    </div>
                    <div className="four_block_midArea_single_textConainter_into">
                      {t("notice.01_1")}
                    </div>
                  </div>
                </div>
                <div className="four_block_midArea_single">
                  <div className="four_block_midArea_single_picContainer">
                    <div className="four_block_midArea_single_picContainer_album"></div>
                  </div>
                  <div className="four_block_midArea_single_textConainter">
                    <div className="four_block_midArea_single_textConainter_title">
                      {t("notice.02")}
                    </div>
                    <div className="four_block_midArea_single_textConainter_into">
                      {t("notice.02_1")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="four_block_midArea_rowArea">
                <div className="four_block_midArea_single">
                  <div className="four_block_midArea_single_picContainer">
                    <div className="four_block_midArea_single_picContainer_key_ring"></div>
                  </div>
                  <div className="four_block_midArea_single_textConainter">
                    <div className="four_block_midArea_single_textConainter_title">
                      {t("notice.key03")}
                    </div>
                    <div className="four_block_midArea_single_textConainter_into">
                      {t("notice.key03_1")}
                      <br />
                      {t("notice.key03_size")}
                    </div>
                  </div>
                </div>
                <div className="four_block_midArea_single">
                  <div className="four_block_midArea_single_picContainer">
                    <div className="four_block_midArea_single_picContainer_badge"></div>
                  </div>
                  <div className="four_block_midArea_single_textConainter">
                    <div className="four_block_midArea_single_textConainter_title">
                      {t("notice.badge04")}
                    </div>
                    <div className="four_block_midArea_single_textConainter_into">
                      {t("notice.badge04_1")}
                      <br />
                      {t("notice.badge04_size")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="four_block_midArea_rowArea">
                <div className="four_block_midArea_single">
                  <div className="four_block_midArea_single_picContainer">
                    <div className="four_block_midArea_single_picContainer_postcard"></div>
                  </div>
                  <div className="four_block_midArea_single_textConainter">
                    <div className="four_block_midArea_single_textConainter_title">
                      {t("notice.card05")}
                    </div>
                    <div className="four_block_midArea_single_textConainter_into">
                      {t("notice.card05_1")}
                      <br />
                      {t("notice.card05_size")}
                    </div>
                  </div>
                </div>
                <div className="four_block_midArea_single">
                  <div className="four_block_midArea_single_picContainer">
                    <div className="four_block_midArea_single_picContainer_paper_model"></div>
                  </div>
                  <div className="four_block_midArea_single_textConainter">
                    <div className="four_block_midArea_single_textConainter_title">
                      {t("notice.paper06")}
                    </div>
                    <div className="four_block_midArea_single_textConainter_into">
                      {t("notice.paper06_1")}
                      <br />
                      {t("notice.paper06_size")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="four_block_bottomArea">
              <div className="four_block_bottomArea_title">
                <div className="line_container_left">
                  <div className="line"></div>
                  <div className="cross"></div>
                </div>
                <span>{t("notice.07")}</span>
                <div className="line_container_right">
                  <div className="cross"></div>
                  <div className="line"></div>
                </div>
              </div>
              <div className="four_block_bottomArea_des">
                {t("notice.07_1")}
                <br />
                {t("notice.07_2")}
                <br />
                {t("notice.07_3")}
                <br />
                {t("notice.07_4")}
                <br />
                {t("notice.07_5")}
                <br />
                {t("notice.07_6")}
                <br />
                <br />
                {t("notice.07_7")}
                <br />
                {t("notice.07_8")}
                <br />
                {t("notice.07_9")}
              </div>
            </div>
            <div className="four_block_bottomArea_button">
              <a href={url} target="_blank" rel="noreferrer">
                <div className="button">{t("other.buy")}</div>
              </a>
            </div>
            <div className="four_block_bottomArea_arrow">
              <a className="arrow reverse" onClick={() => setOpen(!open)}>
                <HiChevronDoubleDown
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                ></HiChevronDoubleDown>
                {t("other.hide")}
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="four_block_rightArea">
        <div className="right_top"></div>
        <div className={open ? "right_bottom" : "right_bottom none"}></div>
      </div>
    </div>
  );
};
