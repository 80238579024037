import i18n from "i18next";
export function handleScoll() {
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos || prevScrollpos < 100) {
      document.querySelector(".App-header").style.top = "0";
    } else {
      document.querySelector(".App-header").style.top = "-100px";
    }
    prevScrollpos = currentScrollPos;
  };
}

export function handleScollStop() {
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.querySelector(".App-header").style.top = "0";
    } else {
      document.querySelector(".App-header").style.top = "0";
    }
    prevScrollpos = currentScrollPos;
  };
}

export function handlePostion(props) {
  let id = document.getElementById(props);
  id.scrollIntoView();
}

export function handleClick(lang) {
  i18n.changeLanguage(lang);
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
