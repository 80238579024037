import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { EAGER } from "./pages/EAGER";
import { HideBehind } from "./pages/HideBehind";
import { EXIST } from "./pages/EXIST";
import { EAGEREN } from "./pages/EAGEREN";
import { EyePage } from "./pages/Eye";
import "./App.css";
import { NavbarContainer } from "./components/Navbar";
import { Footer } from "./components/Footer";
import "./i18n";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavbarContainer />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="EAGER" element={<EAGER />} />
          <Route path="EAGER/EN" element={<EAGEREN />} />
          <Route path="HideBehind" element={<HideBehind />} />
          <Route path="EXIST" element={<EXIST />} />
          <Route path="eye" element={<EyePage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
